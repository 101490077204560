$colors: "default" #3e4148 #212529, "hero" #303339 #212529,
  // "bronze" #e47d22 #c35200,
  "bronze" #ff8a65 #ff5722,
  // "silver" #cfd2d3 #87888c,
  "silver" #e0e0e0 #9e9e9e,
  // "gold" #f0f328 #f5941d,
  "gold" #ffd54f #ffc107,
  // "platinum" #85e3ae #6fbcae,
  "platinum" #4db6ac #009688,
  // "diamond" #44c4fd #008fb9,
  "diamond" #4fc3f7 #03a9f4,
  // "streak" #ba68c8 #9c27b0;
  "streak" #ba68c8 #9c27b0;

.badge {
  height: 64px;
  padding-left: 64px;
  padding-right: 0;
  position: relative;
  overflow: hidden;

  .text {
    padding: 0 24px;
    font-size: 48px;
    font-family: "Chakra Petch", sans-serif;
    line-height: 1.05;
    font-weight: 700;
  }

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 64px;
    width: 64px;

    img {
      height: 48px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    }
  }

  &.badge-inline {
    height: auto;
    padding: 0;
    padding-right: 2px;
    background: none;
    display: inline;
    font-size: inherit;

    .text {
      font-size: inherit;
      padding: 0;
      padding-left: 24px;
      display: inline;
    }

    .icon {
      display: inline;
      position: relative;
      background: none;

      img {
        display: inline;
        position: absolute;
        top: 50%;
        left: auto;
        height: 24px;
        transform: translateY(-50%);
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
      }
    }
  }

  &.badge-small {
    height: 32px;
    padding-left: 32px;

    .text {
      padding: 0 12px;
      font-size: 24px;
    }

    .icon {
      height: 32px;
      width: 32px;

      img {
        height: 24px;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
      }
    }
  }
}

@each $color, $light, $dark in $colors {
  .background-#{$color}-light {
    background: $light;
  }

  .background-#{$color}-dark {
    background: $dark;
  }

  .badge-#{$color} {
    background: $dark;

    &.badge-inline {
      color: $light;
    }

    .icon {
      background: $light;
    }
  }
}

body {
  @extend .background-default-light;
}

.card {
  border: none;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.quest {
  .thumbnail {
    height: 192px;
    background-position: center;
    background-size: cover;
    position: relative;

    .goal {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      h3 {
        font-weight: 700;
        color: #fff;
        font-family: "Chakra Petch", sans-serif;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 32px;
        transform: translate(-50%, -50%);
        -webkit-text-stroke: 1.5px black;
        text-shadow: 0 0 5px #000, 0 0 10px #000;
        text-transform: uppercase;
      }
    }

    .info-panel {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.375);

      .nt {
        margin-top: -0.25rem !important;
      }
    }
  }

  .badge {
    width: 100%;
    border-radius: 0;
    padding-left: 48px;

    .icon {
      width: 48px;
    }
  }
}

.navbar {
  .navbar-brand {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 600;

    img {
      margin-top: -4px;
    }
  }
}

.category {
  .icon {
    position: relative;
    transform: rotate(-11.25deg);

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: #260007;
    }

    i.top {
      z-index: 100;
    }

    i.middle {
      z-index: 50;
      text-shadow: -1px -1px 0px #fffffa;
    }

    i.bottom {
      z-index: 10;
      -webkit-text-stroke: 5px #f4eb3c;
    }
  }

  h2 {
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Chakra Petch", sans-serif;
  }
}

.pack {
  h1 {
    font-family: "Chakra Petch", sans-serif;
  }
  .nt {
    margin-top: -0.5rem;
  }

  h6 {
    font-weight: 500;

    .timer {
      font-weight: 700;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

button.wrapper {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
}

.navbar {
  .nav-link {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-left: 4px;
    border-radius: 4px;

    &.active {
      background: #111315;
    }
  }
}

h1,
h2 {
  font-family: "Chakra Petch", sans-serif;
}
